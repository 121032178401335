export class PagamentosResumoDto {
  /**
   * Quantidade de pagamentos a receber
   */
  quantidadeReceber: number;
  /**
   * Quantidade de pagamentos pendentes
   */
  quantidadePendente: number;
  /**
   * Quantidade de pagamentos recebidos no mês
   */
  quantidadeRecebidoTotal: number;
  /**
   * Quantidade de pagamentos recebidos que vencem no mês
   */
  quantidadeMesRecebido: number;
  /**
   * Quantidade de pagamentos inadimplentes
   */
  quantidadeInadimplentes: number;
  /**
   * Valor total dos pagamentos a receber
   */
  valorReceber?: number;
  /**
   * Valor total dos pagamentos pendentes
   */
  valorPendente?: number;
  /**
   * Valor total dos pagamentos recebidos no mês
   */
  valorRecebidoTotal?: number;
  /**
   * Valor total dos pagamentos recebidos que vencem no mês
   */
  valorMesRecebido?: number;
  /**
   * Valor total dos pagamentos inadimplentes
   */
  valorInadimplentes?: number;
}
