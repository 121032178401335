import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { Camera, CameraResultType, Photo } from '@capacitor/camera';
import { ToastService } from '../../shared';

export interface Arquivo {
  id?: string;
  base64: string;
  arquivoPdf: boolean;
  formatoArquivo?: string;
}

export type RemoverArquivoOutput = {
  ids: string[];
  semArquivosNoDocumento: boolean;
}

const MAX_PIXELS = 5000000; // 5 MP
const QUALIDADE_PADRAO = 0.8;

@Directive()
export abstract class UploadFotoComponent {
  @Input() readonly arquivoPdf = true;
  @Input() podeEnviarVariosArquivos = true;
  @Input() podeEnviarPDF = true;
  @Input() urlImagem: string;
  @Input() imagensBase64: Arquivo[] = [];
  @Output() aoRealizarUpload = new EventEmitter<File[]>();
  @Output() aoRealizarUploadBase64 = new EventEmitter<Arquivo[]>();
  @Output() aoRemoverArquivo = new EventEmitter<RemoverArquivoOutput>();

  protected imagensArquivos: File[] = [];
  protected uploadEstaDesabilitado = false;

  public indiceImagemSelecionada = 0;
  public imagensRemovidas: string[] = [];

  constructor(
    protected readonly toastService: ToastService,
  ) { }

  public get urlImagemSelecionada(): string {
    const file = this.imagensBase64[this.indiceImagemSelecionada]?.base64 || this.urlImagem;
    return file ? file : '';
  }

  public async selecionarImagem(): Promise<void> {
    if (this.uploadEstaDesabilitado) {
      return;
    }

    this.uploadEstaDesabilitado = true;
    let formatoArquivo: string;
    let arquivoPdf = false;
    let arquivoBase64: Photo;

    try {
      arquivoBase64 = await Camera.getPhoto({
        quality: 80,
        saveToGallery: false,
        allowEditing: true,
        resultType: CameraResultType.Base64,
      });
    } catch (error) {
      // não é necessário fazer nada, o usuário apenas cancelou a câmera
    }

    if (arquivoBase64.format === 'pdf') {
      if (!this.podeEnviarPDF) {
        return;
      }
      formatoArquivo = 'application/pdf';
      arquivoPdf = true;
    } else {
      formatoArquivo = `image/${arquivoBase64.format}`;
      arquivoPdf = false;
    }

    this.gerarArquivoEEmitirUpload(arquivoBase64.base64String, formatoArquivo, arquivoPdf);
    this.uploadEstaDesabilitado = false;
  }

  protected async gerarArquivoEEmitirUpload(base64String: string, formatoArquivo: string, arquivoPdf: boolean) {
    if (base64String.includes('data:')) {
      base64String = base64String.split(',')[1];
    }
    const arquivo = this.b64toBlob(base64String, formatoArquivo);
    const base64Formatado = `data:${formatoArquivo};base64,` + base64String;
    try {
      this.urlImagem = await this.redimensionarImagem(base64Formatado);
    } catch (error) {
      this.toastService.error('Erro ao carregar imagem. Por favor tente novamente.').subscribe();
      throw error;
    }

    const arquivoEnviado: Arquivo = { base64: this.urlImagem, arquivoPdf, formatoArquivo };
    if (this.podeEnviarVariosArquivos) {
      this.imagensBase64.push(arquivoEnviado);
      this.imagensArquivos.push(arquivo);
      this.indiceImagemSelecionada = this.imagensBase64.length - 1;
    } else {
      this.imagensBase64 = [arquivoEnviado];
      this.imagensArquivos = [arquivo];
    }

    this.aoRealizarUpload.emit(this.imagensArquivos);
    this.aoRealizarUploadBase64.emit(this.imagensBase64);
  }

  public removerImagem(): void {
    const arquivoSelecionado = this.imagensBase64[this.indiceImagemSelecionada];
    if (!arquivoSelecionado) {
      return;
    }


    this.imagensArquivos.splice(this.indiceImagemSelecionada, 1);
    this.imagensBase64.splice(this.indiceImagemSelecionada, 1);

    if (arquivoSelecionado.id) {
      this.imagensRemovidas.push(arquivoSelecionado.id);
      this.aoRemoverArquivo.emit({ ids: this.imagensRemovidas, semArquivosNoDocumento: !this.imagensBase64.length });
    }

    const estaNaUltimaPagina = this.indiceImagemSelecionada === this.imagensBase64.length;
    if (estaNaUltimaPagina && this.indiceImagemSelecionada > 0) {
      this.indiceImagemSelecionada -= 1;
    }

    this.aoRealizarUpload.emit(this.imagensArquivos);
    this.aoRealizarUploadBase64.emit(this.imagensBase64);
    this.urlImagem = '';
  }

  private b64toBlob(b64Data: string, contentType = ''): File {
    const rawData = window.atob(b64Data);
    const bytes = new Array(rawData.length);
    for (let x = 0; x < rawData.length; x++) {
      bytes[x] = rawData.charCodeAt(x);
    }
    const arr = new Uint8Array(bytes);
    const blob = new Blob([arr], { type: contentType });

    return new File([blob], `${new Date().getTime()}`, { type: contentType });
  }

  /**
   * Redimensiona a imagem para 5 MP.
   *
   * @param base64Image Base64 da imagem a ser redimensionada
   * @returns Uma Promise com o base64 da imagem redimensionada
   */
  private async redimensionarImagem(base64Image: string): Promise<string> {
    if (base64Image.includes('application/pdf')) {
      return base64Image;
    }

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = base64Image;
      img.onload = () => {
        let width = img.width;
        let height = img.height;

        // Calcular o número total de pixels
        const totalPixels = width * height;

        // Se a imagem tiver mais de 5 MP, redimensionar
        if (totalPixels > MAX_PIXELS) {
          const scale = Math.sqrt(MAX_PIXELS / totalPixels);
          width = Math.floor(width * scale);
          height = Math.floor(height * scale);
        }

        // Criação do canvas para redimensionar a imagem
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        // Converte para base64 e retorna
        resolve(canvas.toDataURL('image/jpeg', QUALIDADE_PADRAO)); // 70% de qualidade
      };

      img.onerror = (error) => reject(error);
    });
  }

  public irParaProximaImagem(): void {
    if (this.imagensBase64[this.indiceImagemSelecionada] && this.podeEnviarVariosArquivos) {
      this.indiceImagemSelecionada += 1;
    }
  }

  public irParaImagemAnterior(): void {
    if (this.indiceImagemSelecionada > 0 && this.podeEnviarVariosArquivos) {
      this.indiceImagemSelecionada -= 1;
    }
  }

  protected resetar(): void {
    this.imagensBase64 = [];
    this.imagensArquivos = [];
    this.indiceImagemSelecionada = 0;
    this.urlImagem = '';
    this.imagensRemovidas = [];
  }

}
