import { Expose, plainToInstance, Transform } from 'class-transformer';
import { IsEnum, IsNotEmpty, IsNumber, IsString, Max, Min, ValidateIf } from 'class-validator';
import { MapaTipo } from './mapa-tipo.enum';

export const LAT_MIN = -33.75194444;
export const LAT_MAX = 5.27194444;
export const LNG_MIN = -73.99055556;
export const LNG_MAX = -34.79277778;

export class MapaCriarDto {
  @Expose()
  @IsEnum(MapaTipo)
  @IsNotEmpty()
  tipo: MapaTipo

  @Expose()
  @IsString()
  @IsNotEmpty()
  @ValidateIf(mapa => mapa.tipo === MapaTipo.MAPBOX)
  idMapa: string;

  @Expose()
  @Transform(({ value }) => +value)
  @IsNumber()
  @IsNotEmpty()
  zoomMinimo: number;

  @Expose()
  @Transform(({ value }) => +value)
  @IsNumber()
  @IsNotEmpty()
  zoomMaximo: number;

  @Expose()
  @Transform(({ value }) => +value)
  @IsNumber()
  @IsNotEmpty()
  zoomInicial: number;

  /**
   * Cria uma nova instância de MapaDto
   * @param props DTO de Mapa
   * @returns nova instância de Mapa
   */
  static from(props?: Partial<MapaDto>): MapaDto {
    return plainToInstance(MapaDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}

export class MapaDto extends MapaCriarDto {
  @Expose()
  id: string;

  @Expose()
  @Transform(({ value }) => +value)
  @IsNumber()
  @Min(LAT_MIN)
  @Max(LAT_MAX)
  @IsNotEmpty()
  latitudeMinima: number;

  @Expose()
  @Transform(({ value }) => +value)
  @IsNumber()
  @Min(LAT_MIN)
  @Max(LAT_MAX)
  @IsNotEmpty()
  latitudeMaxima: number;

  @Expose()
  @Transform(({ value }) => +value)
  @IsNumber()
  @Min(LNG_MIN)
  @Max(LNG_MAX)
  @IsNotEmpty()
  longitudeMinima: number;

  @Expose()
  @Transform(({ value }) => +value)
  @IsNumber()
  @Min(LNG_MIN)
  @Max(LNG_MAX)
  @IsNotEmpty()
  longitudeMaxima: number;
}
