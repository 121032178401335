import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AngularFireAuth } from '@angular/fire/auth';
import Bugsnag from '@bugsnag/js';
import { resetStores } from '@datorama/akita';
import { NavController } from '@ionic/angular';
import { CreateUserDto, UpdateUserDto, UserDto } from '@usucampeao/lib-reurb-simplificado';
import { LoadingService, ToastService } from '@usucampeao/ui-mobile';
import 'firebase/auth';
import { Observable, of } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { PushNotificationService } from '../../../shared/services/push-notification.service';
import { AuthState, AuthStore } from './auth.store';
import { IndexedDBService } from './indexed-db.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly analytics: AngularFireAnalytics,
    private readonly angularFireAuth: AngularFireAuth,
    private readonly authStore: AuthStore,
    private readonly http: HttpClient,
    private readonly indexedDBService: IndexedDBService,
    private readonly loadingService: LoadingService,
    private readonly navController: NavController,
    private readonly pushNotificationService: PushNotificationService,
    private readonly toastService: ToastService,
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async login(phone: string, code: any) {
    return await this.angularFireAuth.signInWithPhoneNumber(phone, code);
  }

  public criarConta(usuario: CreateUserDto): Observable<void | UserDto> {
    return this.http.post<CreateUserDto>(`/users/`, usuario)
      .pipe(
        switchMap(() => this.buscarUsuario()),
        catchError(error => {
          Bugsnag.notify(error, (event) => {
            event.addMetadata('error response', error.response);
            event.addMetadata('error payload', error.payload);
          });
          this.logout();
          return this.toastService.error('Erro ao buscar dados do usuário. Por favor realize o login novamente.');
        })
      );
  }

  public async logout(): Promise<void> {
    await this.loadingService.createLoader();

    this.pushNotificationService.removerDispositivo()
      .pipe(
        catchError(error => {
          const usuarioId = this.authStore.getValue().id;
          Bugsnag.notify(error, (event) => {
            event.context = 'Erro ao remover dispositivo do usuário.';
            event.addMetadata('payload', { usuarioId });
          });
          return of(null);
        }),
        finalize(() => {
          resetStores();
          this.indexedDBService.limparUsuarioId();
          this.angularFireAuth.signOut();
          this.navController.navigateRoot('login', { animated: true });
          this.loadingService.dismiss();
        }),
      )
      .subscribe();
  }

  public atualizarFotoDePerfil(file: File): Observable<string> {
    this.authStore.setLoading(true);
    const formData = new FormData();
    formData.append('photo', file);
    return this.http.put(`/users/my-photo`, formData, { responseType: 'text' })
      .pipe(
        tap(response => this.authStore.update({ photoId: response })),
        tap(() => this.buscarUsuario()),
        finalize(() => this.authStore.setLoading(false))
      );
  }

  public buscarUsuario(): Observable<UserDto> {
    return this.http.get<UserDto>(`/users/me`)
      .pipe(
        tap(usuario => {
          if (usuario) {
            this.authStore.update(usuario);
            this.pushNotificationService.inicializar();
            this.indexedDBService.salvarUsuarId(usuario.id);
            this.analytics.setUserId(usuario.id);
          }
        }),
        catchError(error => {
          if (error.status === HttpStatusCode.Unauthorized) {
            return of(null);
          }

          throw error;
        })
      );
  }

  public atualizarUsuario(data: UpdateUserDto): Observable<UserDto> {
    return this.http.put<UserDto>(`/users/me`, data)
      .pipe(
        tap(usuario => this.authStore.update(usuario))
      );
  }

  public atualizarEstado(state: Partial<AuthState>): void {
    this.authStore.update(state);
  }
}
