import { SortDirection } from '@usucampeao/utils';
import { RegistrationStatus } from './registration-status.enum';

export type CadastroListagemBeneficiariosDTO = {
  cadastroId: string;
  proprietario: string;
  lote: string;
  quadra: string;
  inadimplente: boolean;
  cadastroStatus: RegistrationStatus;
};

export enum ListagemBeneficiariosStatusPagamentoTipo {
  ADIMPLENTES = 'ADIMPLENTES',
  INADIMPLENTES = 'INADIMPLENTES',
  TODOS = 'TODOS',
};

export type CadastroListagemBeneficiarioParams = {
  pagina?: number;
  tamanhoPagina?: number;
  statusFiltro?: RegistrationStatus[];
  proprietarioDirecaoOrdenacao?: SortDirection;
  statusPagamentoTipo?: ListagemBeneficiariosStatusPagamentoTipo;
};
