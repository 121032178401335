<div class="content-vertical-space-between">
  <ion-grid class="ion-padding ion-no-margin">
    <ion-row class="ion-padding-top ion-padding-bottom ion-justify-content-center">
      <ion-col class="ion-no-padding ion-padding-top" sizeXs="12" sizeSm="6">
        <ion-text class="title title--large">
          Confirme o código de telefone
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row class="ion-padding-top ion-justify-content-center">
      <ion-col class="ion-no-padding" sizeXs="12" sizeSm="6">
        <ion-text class="font-text">
          Enviamos um SMS para o número
          <strong>{{ (authQuery?.telefone$ | async) | mask: '+00 (00) 00000-0000' }}</strong>,
          confirme o código enviado nos campos abaixo.
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-padding-top">
      <ion-col sizeMd="6" sizeLg="3" class="ion-no-padding ion-no-margin">
        <form mode="md" id="login-form-codigo" [formGroup]="form" (ngSubmit)="onSubmit()">
          <usucampeao-input type="tel" label="Código" mask="000000" controlName="code"></usucampeao-input>
        </form>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-padding-top">
      <ion-col sizeMd="6" sizeLg="3">
        <ion-button type="submit" mode="md" expand="block" (click)="onSubmit()">
          Continuar
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-text>
          <p class="font-text">Não recebeu o SMS?</p>
          <p class="text-link" (click)="goBack()"><strong>Trocar número de celular</strong></p>
          <p>ou</p>
          <p *ngIf="!reenviarCodigoDesabilitado" class="text-link" (click)="resendCode()"><strong>Reenviar código
            </strong></p>
          <p *ngIf="reenviarCodigoDesabilitado">
            Você poderá gerar outro código em
            <strong>
              <ng-container *ngIf="pedirNovoCodigoMinutos">
                {{pedirNovoCodigoMinutos}}m
              </ng-container>
              {{pedirNovoCodigoSegundos}}s
            </strong>
          </p>
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
  <usucampeao-footer></usucampeao-footer>
</div>
