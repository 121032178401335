import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroContratoAssinaturaTipoEnvioLink, CadastroContratoTipoAssinatura } from '@usucampeao/interfaces';
import {
  AlteracaoDto,
  CadastroComentarioListaDto,
  CadastroContratoDto,
  CadastroCriarDto,
  CadastroDocumentoTipo,
  CadastroSelecionarPagamentosDto,
  CadastroSolicitarAssinaturaContratoDto,
  DocumentFileDto,
  FileDto,
  ListRegistration,
  PagamentoListagemDto,
  RegistrationSimplifiedDto,
  RegistrationStatus,
  RevisaoDto,
  TabelaPrecoDetalhesDto,
  TipoAlteracao
} from '@usucampeao/lib-reurb-simplificado';
import { CadastroStateDto, ImovelStateDto, LocalizacaoService } from '@usucampeao/ui-mobile';
import { Observable } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { ImoveisStore } from '../imoveis/state/imoveis.store';
import { PagamentosService } from '../pagamentos/state/pagamentos.service';
import { CadastroRascunho, CadastrosStore } from './cadastros.store';

@Injectable({
  providedIn: 'root',
})
export class CadastroService {

  constructor(
    private http: HttpClient,
    private cadastroStore: CadastrosStore,
    private imovelStore: ImoveisStore,
    private localizacaoService: LocalizacaoService,
    private pagamentoService: PagamentosService,
  ) { }

  /**
   * Lista os cadastros do usuário
   * @returns cadastros do usuário
   */
  public buscarCadastrosDoUsuario(): Observable<ListRegistration[]> {
    return this.http.get<ListRegistration[]>('/registrations/user')
      .pipe(
        tap(cadastros => this.cadastroStore.set(cadastros)),
      );
  }

  /**
   * Adiciona um novo cadastro
   * @param cadastroCriarDto dados para criar novo cadastro
   * @returns dados de criação de cadastro
   */
  public create(
    projetoId: string,
    cadastroCriarDto: CadastroCriarDto,
  ): Observable<CadastroCriarDto> {
    this.cadastroStore.setLoading(true);
    return this.http
      .post<CadastroCriarDto>(`/v2/projetos/${projetoId}/cadastros`, cadastroCriarDto)
      .pipe(
        tap(cadastroCriado => {
          this.cadastroStore.add({
            id: cadastroCriado.id,
            blockId: cadastroCriado.blockId,
            lotId: cadastroCriado.lotId,
            projetoFid: cadastroCriado.projetoFid,
            propertyId: cadastroCriado.propertyId,
          } as any);

          this.localizacaoService.salvarLocalizacaoSelecionadaPeloUsuario({
            cep: cadastroCriarDto.address.zipCode,
            logradouro: cadastroCriarDto.address.street,
            numero: cadastroCriarDto.address.number,
            bairro: cadastroCriarDto.address.neighborhood,
            cidade: cadastroCriarDto.address.city,
            estado: cadastroCriarDto.address.state,
          });
          this.salvarRascunhoCadastro({
            id: cadastroCriado.id,
            imovelId: cadastroCriado.propertyId,
            proprietarioId: cadastroCriado.proprietarioId,
            possuiOutroImovel: cadastroCriarDto.possuiOutroImovel,
            situation: cadastroCriarDto.situation,
          });
        }),
        finalize(() => this.cadastroStore.setLoading(false)),
        take(1)
      );
  }

  /**
   * Busca o cadastro por ID.
   * @param id ID do cadastro
   * @returns Dados simplificados do cadastro.
   */
  public buscarCadastro(id: string): Observable<RegistrationSimplifiedDto> {
    return this.http
      .get<RegistrationSimplifiedDto>(`/registrations/${id}/simplified`)
      .pipe(
        tap(cadastro => this.cadastroStore.upsert(id, cadastro))
      );
  }

  /**
   * Cancela o cadastro
   *
   * @param cadastroId - ID do cadastro
   */
  public cancelarCadastro(cadastroId: string): Observable<void> {
    return this.http.put<void>(`/registrations/${cadastroId}/cancelar`, { cancelamentoObservacoes: 'Cancelado pelo cliente' })
      .pipe(
        tap(() => this.cadastroStore.remove(cadastroId)),
        tap(() => this.limparCadastroEmRascunho()),
      );
  }

  /**
   * Carrega o cadastro, juntamente com o imóvel e os pagamentos noe estado.
   * @param id id do cadastro
   * @returns listagem de pagamentos do cadastro
   */
  public carregarCadastro(id: string): Observable<PagamentoListagemDto[]> {
    return this.buscarCadastro(id)
      .pipe(
        map(cadastro => ({
          id: cadastro.propertyId,
          address: cadastro.address,
          files: cadastro.files,
        }) as ImovelStateDto),
        tap(imovel => this.imovelStore.add(imovel)),
        tap(imovel => this.imovelStore.update(imovel.id, imovel)),
        switchMap(() => this.pagamentoService.buscarPagamentosPorCadastroId(id)),
      );
  }

  public buscarFormasDePagamentoDisponiveisParaOCadastro(cadastroId: string): Observable<TabelaPrecoDetalhesDto[]> {
    return this.http.get<TabelaPrecoDetalhesDto[]>(`/registrations/${cadastroId}/formas-pagamento`);
  }

  /**
   * Busca as alterações necessárias para um cadastro
   * @param id id do atendimento
   * @param tipo da pagina
   * @returns lista de alterações necessárias do cadastro
   */
  public buscarAlteracoesNecessarias(id: string, tipo?: TipoAlteracao): Observable<AlteracaoDto[]> {
    return this.http.get<AlteracaoDto[]>(`/registrations/${id}/alteracoes`, tipo ? { params: { tipo } } : {});
  }

  /**
   * Busca a última revisão de um cadastro.
   * É possível retornar somente a última revisão que foi reprovada.
   * Para isso, é necessário passar a flag ultimaRevisaoReprovada.
   * @param cadastroId ID do cadastro
   * @param ultimaRevisaoReprovada Flag para indicar se é pra listar somente revisões reprovadas
   * @returns Última revisão de um cadastro.
   */
  public buscarUltimaRevisao(cadastroId: string, ultimaRevisaoReprovada?: boolean): Observable<RevisaoDto> {
    return this.http.get<RevisaoDto>(`/cadastros/${cadastroId}/revisao-atual`, {
      ...ultimaRevisaoReprovada ? { params: { ultimaRevisaoReprovada } } : {},
    })
      .pipe(
        tap(revisao => this.cadastroStore.upsert(cadastroId, { id: cadastroId, revisao })),
      );
  }

  /**
   * Busca a previa do contrato de um cadastro
   * @param id ID do cadastro
   * @param dadosPagamento Dados de pagamento selecionado pelo cliente
   * @returns Previa do contrato
   */
  public buscarPreviaContrato(id: string, dadosPagamento: CadastroSelecionarPagamentosDto): Observable<CadastroContratoDto> {
    return this.http.post<CadastroContratoDto>(`/cadastros/${id}/contrato/previa`, dadosPagamento);
  }

  /**
   * Altera a observação do cadastro
   * @param cadastroId ID do cadastro
   * @param observacao Observação do cadastro
   */
  public alterarObservacao(cadastroId: string, observacao: string): Observable<void> {
    return this.http
      .patch<void>(`/registrations/${cadastroId}/observacao`, { observacao })
      .pipe(
        tap(() => this.cadastroStore.upsert(cadastroId, { observacao }))
      );
  }

  /**
   * Busca o contrato de um cadastro
   * @param id ID do cadastro
   * @returns Contrato do cadastro
   */
  public buscarContrato(id: string): Observable<CadastroContratoDto> {
    return this.http.get<CadastroContratoDto>(`/registrations/${id}/dados-contrato`);
  }

  /**
   * Fecha o contrato de um atendimento
   * @param id ID do cadastro
   * @param dadosPagamento Dados de pagamento selecionado pelo cliente
   */
  public solicitarAssinaturaContrato(id: string, dadosPagamento: CadastroSolicitarAssinaturaContratoDto): Observable<void> {
    return this.http.put<void>(`/v2/cadastros/${id}/contrato/solicitar-assinatura`, dadosPagamento)
      .pipe(
        tap(() => this.cadastroStore.update(id, { status: RegistrationStatus.AGUARDANDO_ASSINATURA_CONTRATO })),
        tap(() => this.carregarCadastro(id))
      );
  }

  /**
  * Seleciona o tipo de contrato.
  * @param id ID do cadastro
  * @param tipo Tipo de assinatura de contrato
  */
  public selecionarTipoAssinatura(id: string, tipo: CadastroContratoTipoAssinatura): Observable<void> {
    return this.http.post<void>(`/cadastros/${id}/contrato/tipo-assinatura`, { tipo })
      .pipe(
        tap(() => this.cadastroStore.update(id, { contratoTipoAssinatura: tipo })),
      );
  }

  /**
   * Assina o contrato por meio do upload da foto do contrato assinado.
   * @param id ID do cadastro
   * @param arquivos arquivos do contrato
   * @returns
   */
  public assinaturaContratoFisico(id: string, arquivos: File[]): Observable<PagamentoListagemDto[]> {
    const formData = new FormData();

    arquivos.forEach((arquivo) => {
      formData.append('files', arquivo);
    });

    return this.http.post<void>(`/cadastros/${id}/contrato/assinatura-upload`, formData)
      .pipe(
        switchMap(() => this.carregarCadastro(id)),
        tap(() => {
          this.limparCadastroEmRascunho();
          this.localizacaoService.limparLocalizacaoSelecionadaPeloUsuario();
        }),
      );
  }

  /**
   * Assina o contrato por meio de um link de assinatura.
   *
   * @param id ID do cadastro
   * @param tipoEnvio Tipo de envio do link de assinatura. (Whatsapp ou Email)
   */
  public assinaturaContratoPorLink(id: string, tipoEnvio: CadastroContratoAssinaturaTipoEnvioLink): Observable<PagamentoListagemDto[]> {
    return this.http.post<void>(`/cadastros/${id}/contrato/assinatura-por-link`, { tipoEnvio })
      .pipe(
        switchMap(() => this.carregarCadastro(id)),
        tap(() => {
          this.limparCadastroEmRascunho();
          this.localizacaoService.limparLocalizacaoSelecionadaPeloUsuario();
        }),
      );
  }


  public enviarParaAnalise(id: string): Observable<void> {
    return this.http.put<void>(`/registrations/${id}/send-for-analysis`, {})
      .pipe(
        tap(() => this.cadastroStore.update(id, { status: RegistrationStatus.IN_ANALYSIS })),
      );
  }

  /**
   * Busca os arquivos de um documento
   * @param documentoId ID do documento
   */
  public buscarArquivosDocumento(documentoId: string): Observable<FileDto[]> {
    return this.http.get<FileDto[]>(`/documents/${documentoId}/files`);
  }

  /**
  * Faz o upload de um documento do cadastro.
  * @param id ID do cadastro
  * @param arquivos arquivos a serem enviados
  */
  public uploadDocumentosDoCadastro(id: string, arquivos: File[], tipo: CadastroDocumentoTipo): Observable<void> {
    const formData = new FormData();

    arquivos.forEach((arquivo) => {
      formData.append('files', arquivo);
    });
    formData.append('type', tipo);
    return this.http.post<void>(`/registrations/${id}/documents`, formData);
  }

  /**
   * Atualiza um documento do cadastro e carrega a lista de documentos atualizados na store.
   * Esse método adiciona e/ou remove arquivos de um documento.
   * @param cadastroId ID do cadastro
   * @param documentoId ID do documento
   * @param arquivos Arquivos a serem enviados
   * @param arquivosRemovidos Arquivos a serem removidos
   * @returns Lista atualizada de documentos do imóvel
   */
  public atualizarDocumento(cadastroId: string, documentoId: string, arquivos: File[], arquivosRemovidos: string[]): Observable<DocumentFileDto[]> {
    const formData = new FormData();

    if (arquivosRemovidos) {
      arquivosRemovidos.map(id => formData.append('arquivosRemovidos', id));
    }

    if (arquivos) {
      arquivos.forEach(arquivo => formData.append('files', arquivo));
    }

    return this.http.put(`/registrations/${cadastroId}/documents/${documentoId}/files`, formData, { responseType: 'text' })
      .pipe(
        switchMap(() => this.buscarCadastro(cadastroId)),
        switchMap(() => this.buscarDocumentosDoCadastro(cadastroId)),
      );
  }

  /**
   * Busca os documentos de um cadastro
   * @param id id do cadastro
   * @returns lista de documentos de um cadastro
   */
  public buscarDocumentosDoCadastro(id: string): Observable<DocumentFileDto[]> {
    return this.http.get<DocumentFileDto[]>(`/registrations/${id}/documents`);
  }

  /**
   * Salva o rascunho do cadastro, contentdo o id da quadra, do lote e do projeto.
   * Utilizado na página de endereços para criar o cadastro.
   *
   * @param cadastro Dados do cadastro
   */
  public salvarRascunhoCadastro(cadastro: Partial<CadastroRascunho>): void {
    const cadastroEmRascunho = this.cadastroStore.getValue().cadastroEmRascunho || {};
    this.cadastroStore.update({
      cadastroEmRascunho: {
        ...cadastroEmRascunho,
        ...cadastro,
      }
    });
  }

  /**
   * Busca o cadastro em rascunho.
   *
   * @returns Cadastro em rascunho
   */
  public buscarCadastroEmRascunho(): CadastroRascunho {
    return this.cadastroStore.getValue().cadastroEmRascunho;
  }

  /**
   * Limpa o cadastro em rascunho.
   */
  public limparCadastroEmRascunho(): void {
    this.cadastroStore.update({ cadastroEmRascunho: null });
  }

  public alterarCadastroNoEstado(cadastroId: string, cadastro: Partial<CadastroStateDto>): void {
    this.cadastroStore.upsert(cadastroId, cadastro);
  }

  /**
   * Adiciona um comentário ao cadastro.
   *
   * @param cadastroId - ID do cadastro
   * @param mensagem - Mensagem do comentário
   */
  public adicionarComentarioAoCadastro(cadastroId: string, mensagem: string): Observable<CadastroComentarioListaDto[]> {
    return this.http.post<CadastroComentarioListaDto[]>(`/cadastros/${cadastroId}/comentarios/cliente`, { mensagem })
      .pipe(
        switchMap(() => this.buscarComentariosDoCadastro(cadastroId)),
      );
  }

  /**
   * Lista todos os comentários do cadastro realizados pelo cliente.
   *
   * @param cadastroId - ID do cadastro
   * @returns Lista de comentários do cliente do cadastro
   */
  public buscarComentariosDoCadastro(cadastroId: string): Observable<CadastroComentarioListaDto[]> {
    return this.http.get<CadastroComentarioListaDto[]>(`/cadastros/${cadastroId}/comentarios/cliente`)
      .pipe(
        tap(comentarios => this.cadastroStore.upsert(cadastroId, { comentarios })),
      );
  }

}
